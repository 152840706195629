import { render, staticRenderFns } from "./SearchChain.vue?vue&type=template&id=0b547d56&scoped=true&"
import script from "./SearchChain.vue?vue&type=script&lang=js&"
export * from "./SearchChain.vue?vue&type=script&lang=js&"
import style0 from "./SearchChain.vue?vue&type=style&index=0&id=0b547d56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b547d56",
  null
  
)

export default component.exports