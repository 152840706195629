<template>
  <screen-grid class="admin-gmb-countries">
    <screen-card :title="$t('adminGmb.countries.title')" ratio="1-1" :overflow-hidden="true" :is-loading="isLoading">
      <template v-slot:body>
        <gmap-map
          class="admin-gmb-countries__map"
          :center="{ lat: 34.8422284, lng: 19.5690263 }"
          :zoom="2"
          :options="mapOptions"
          ref="gmap"
        >
        </gmap-map>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import { mapActions, mapState } from 'vuex'
import { injectTooltip, moveTooltip, deleteTooltip } from '@/utils/map.util'

export default {
  name: 'AdminGmbCountries',
  components: {
    ScreenGrid,
    ScreenCard,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      mapOptions: {
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      },
      infoWindow: null,
    }
  },
  mounted() {
    this.initMap()
  },
  computed: {
    ...mapState({
      worldGeoJsons: state => state.gmb.worldGeoJsons,
      availablesCountries: state => state.gmb.availablesCountries,
    }),
  },
  methods: {
    ...mapActions({
      getGeoJsons: 'gmb/getGeoJsons',
    }),
    initMap() {
      this.$refs.gmap.$mapPromise
        .then(map => {
          this.map = map
          if (this.worldGeoJsons.length === 0) {
            return this.getGeoJsons()
          }
          return Promise.resolve()
        })
        .then(() => {
          this.setFeatures()
        })
    },
    setFeatures() {
      this.worldGeoJsons.forEach(feature => {
        try {
          this.map.data.addGeoJson(feature)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`The following geosjon is invalid: ${feature.properties.name}\n${e}`)
        }
      })
      this.setGeoJsonColors()
      this.setEventListener()
    },
    setGeoJsonColors() {
      this.map.data.setStyle(feature => {
        try {
          const isoCode = feature.getProperty('iso_a2')
          if (this.availablesCountries[isoCode] !== undefined) {
            feature.setProperty('isExist', true)
            return {
              fillColor: '#4e5cec',
              strokeWeight: 0.5,
              strokeColor: '#ffffff',
              fillOpacity: 0.9,
            }
          }
          return {
            fillColor: '#d65858',
            strokeWeight: 0.5,
            strokeColor: '#ffffff',
            fillOpacity: 0.9,
          }
        } catch (e) {
          return {
            fillColor: 'transparent',
            strokeWeight: 0,
            fillOpacity: 0,
          }
        }
      })
    },
    setEventListener() {
      this.map.data.addListener('mouseover', e => {
        const isExist = e.feature.getProperty('isExist')
        const featureName = e.feature.getProperty('name')
        this.map.data.revertStyle()
        this.map.data.overrideStyle(e.feature, {
          fillColor: isExist ? '#7c86f1' : '#e08181',
        })
        injectTooltip(e, featureName)
      })
      this.map.data.addListener('mousemove', e => {
        moveTooltip(e)
      })
      this.map.data.addListener('mouseout', () => {
        this.map.data.revertStyle()
        deleteTooltip()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-gmb-countries {
  &__map {
    width: 100%;
    height: 350px;

    @media (min-width: $screen-sm) {
      height: 500px;
    }
  }
}
</style>
