<template>
  <modal name="modal-gmb-hours-types" :title="title" :is-updating="false" :width="900" @closed="closed">
    <template v-slot:container>
      <div class="modal-gmb-hours-types__list" v-if="isMobile || isTablet">
        <ui-data-list :items="params.moreHoursTypes" :is-loading="false" :skeleton-loop="2">
          <template v-slot:skeleton>
            <div class="modal-gmb-hours-types__list__content">
              <skeleton-line height="18px" width="80%" />
              <skeleton-line height="15px" width="50%" margin-top="3px" />
            </div>
          </template>
          <template v-slot:item="slotProps">
            <div class="modal-gmb-hours-types__list__content">
              <div class="modal-gmb-hours-types__list__content__name">
                {{ slotProps.item.displayName }}
              </div>
              <div class="modal-gmb-hours-types__list__content__type">
                {{ slotProps.item.hoursTypeId }}
              </div>
              <div class="modal-gmb-hours-types__list__content__localized">
                {{ slotProps.item.localizedDisplayName }}
              </div>
            </div>
          </template>
        </ui-data-list>
      </div>
      <div class="modal-gmb-hours-types__table" v-if="isDesktop || isLargeScreen">
        <ui-data-table
          :columns="columns"
          :rows="params.moreHoursTypes"
          :total-rows="params.moreHoursTypes.length"
          :with-pagination="false"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { onScroll } from '@/mixins/scroll.mixin'

export default {
  name: 'ModalGmbHoursTypes',
  components: {
    UiDataTable,
    UiDataList,
    SkeletonLine,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  mixins: [onScroll],
  computed: {
    columns() {
      return [
        {
          label: this.$t('adminGmb.hoursTypes.column.displayName'),
          field: 'displayName',
        },
        {
          label: this.$t('adminGmb.hoursTypes.column.hoursTypeId'),
          field: 'hoursTypeId',
        },
        {
          label: this.$t('adminGmb.hoursTypes.column.localizedDisplayName'),
          field: 'localizedDisplayName',
        },
      ]
    },
  },
  methods: {
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-gmb-hours-types {
  &__list {
    position: relative;
    margin: (-$gutter-mobile) (-$gutter-mobile);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;

      &__name {
        font-weight: 600;
      }
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      min-height: 350px;
      max-height: 350px;
    }
  }
}
</style>
