<template>
  <screen-grid class="admin-gmb-search-chain">
    <screen-card :title="$t('adminGmb.searchChain.title')" ratio="1-1" :overflow-hidden="true" :is-loading="isLoading">
      <template v-slot:body>
        <div class="admin-gmb-search-chain__top">
          <ui-input
            class="admin-gmb-search-chain__top__input"
            v-model="query"
            id="query"
            :label="$t('adminGmb.searchChain.query')"
            design="minimalist"
            @input="onInput"
          />
        </div>
        <div class="admin-gmb-search-chain__list" v-if="isMobile || isTablet">
          <ui-data-list :items="chains" :is-loading="isUpdating">
            <template v-slot:skeleton>
              <div class="admin-gmb-search-chain__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
            </template>
            <template v-slot:item="slotProps">
              <div class="admin-gmb-search-chain__list__content">
                <div class="admin-gmb-search-chain__list__content__location-count">
                  {{ slotProps.item.locationCount }}
                </div>
                <div class="admin-gmb-search-chain__list__content__chain-names">
                  {{ slotProps.item.chainNames }}
                </div>
                <div class="admin-gmb-search-chain__list__content__websites">
                  {{ slotProps.item.websites }}
                </div>
              </div>
            </template>
          </ui-data-list>
        </div>
        <div class="admin-gmb-search-chain__table" v-if="isDesktop || isLargeScreen">
          <ui-data-table
            :columns="columns"
            :rows="chains"
            :total-rows="totalRows"
            :is-loading="isLoading || isUpdating"
            :pagination="chainsPagination"
          >
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiInput from '@/components/UI/Input.vue'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { debounce } from '@/utils/func.util'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AdminGmbCountries',
  components: {
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    UiInput,
    UiDataTable,
    UiDataList,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      query: '',
      totalRows: 0,
      isUpdating: false,
    }
  },
  computed: {
    ...mapState({
      chains: state => state.gmb.chains,
      chainsPagination: state => state.gmb.chainsPagination,
    }),
    columns() {
      return [
        {
          label: this.$t('adminGmb.searchChain.column.locationCount'),
          field: 'locationCount',
        },
        {
          label: this.$t('adminGmb.searchChain.column.name'),
          field: 'name',
        },
        {
          label: this.$t('adminGmb.searchChain.column.chainNames'),
          field: 'chainNames',
          width: '400px',
        },
        {
          label: this.$t('adminGmb.searchChain.column.websites'),
          field: 'websites',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getChains: 'gmb/getChains',
      clearStore: 'gmb/clearStore',
    }),
    onInput: debounce(function (value) {
      this.isUpdating = true
      this.getChains(value).then(() => {
        this.totalRows = this.chainsPagination.nbResults
        this.isUpdating = false
      })
    }, 300),
  },
  beforeDestroy() {
    this.clearStore()
  },
}
</script>

<style lang="scss" scoped>
.admin-gmb-search-chain {
  &__top {
    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
    }

    &__input {
      margin-bottom: $gutter-mobile;
      width: 100%;

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-bottom: $gutter-desktop;
      }
    }
  }

  &__list {
    position: relative;
    margin: 0 (-$gutter-mobile) (-$gutter-mobile);

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;

      &__location-count {
        font-weight: 600;
      }
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      min-height: 650px;
      max-height: 650px;
    }
  }
}
</style>
