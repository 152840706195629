<template>
  <screen-grid class="admin-gmb-categories">
    <screen-card :title="$t('adminGmb.categories.title')" ratio="1-1" :is-loading="isLoading">
      <template v-slot:body>
        <div class="admin-gmb-categories__top">
          <ui-dropdown
            class="admin-gmb-categories__top__dropdown"
            id="gmb-countries"
            v-model="countryCode"
            track-by="id"
            label="label"
            :options="countriesOptions"
            :placeholder="$t('adminGmb.categories.countries')"
          />
          <ui-dropdown
            class="admin-gmb-categories__top__dropdown"
            id="gmb-countries"
            v-model="languageCode"
            track-by="id"
            label="label"
            :options="languageCodeOptions"
            :placeholder="$t('adminGmb.categories.languageCodes')"
          />
        </div>
        <div class="admin-gmb-categories__search">
          <ui-data-search
            view="adminGmb"
            v-model="search"
            :element-fixed="elementFixed"
            :is-mobile="isMobile"
            :is-tablet="isTablet"
            :withAddButton="false"
            :with-search="true"
            :with-filter="false"
            :isDisabled="!(countryCode && languageCode)"
          />
        </div>
        <div class="admin-gmb-categories__list" v-if="isMobile || isTablet">
          <ui-data-list
            :no-more-data="!hasMoreCategoriesToLoad"
            :items="categories"
            :is-scrolling="isScrolling"
            :is-loading="isUpdating"
          >
            <template v-slot:skeleton>
              <div class="admin-gmb-categories__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
            </template>
            <template v-slot:item="slotProps">
              <div class="admin-gmb-categories__list__row__content">
                <div class="admin-gmb-categories__list__row__content">
                  <div class="admin-gmb-categories__list__row__content__name">
                    {{ slotProps.item.displayName }}
                  </div>
                  <div class="admin-gmb-categories__list__row__content__category">
                    {{ slotProps.item.categoryId }}
                  </div>
                </div>
                <div class="admin-gmb-categories__list__row__actions">
                  <ui-button
                    v-if="slotProps.item.moreHoursTypes"
                    :label="$t('adminGmb.categories.buttons.seeHoursTypes')"
                    @click="showCategoryHoursTypesDetails(slotProps.item.categoryId, slotProps.item.moreHoursTypes)"
                    class="admin-gmb-categories__list__row__actions__left-button"
                    button="primary"
                  />
                  <ui-button
                    v-if="slotProps.item.serviceTypes"
                    :label="$t('adminGmb.categories.buttons.seeServices')"
                    @click="showCategoryServicesDetails(slotProps.item.categoryId, slotProps.item.serviceTypes)"
                    class="admin-gmb-categories__list__row__actions__left-button"
                    button="primary"
                  />
                  <ui-button
                    :label="$t('adminGmb.categories.buttons.seeAttributes')"
                    @click="showCategoryAttributesDetails(slotProps.item.categoryId)"
                    button="primary"
                  />
                </div>
              </div>
            </template>
          </ui-data-list>
          <div class="admin-gmb-categories__list__button">
            <ui-button
              :label="$t('gmbQa.buttons.loadMore')"
              v-if="hasMoreCategoriesToLoad"
              @click="loadMoreGmbCategories"
              button="cta"
              variant="data"
            />
          </div>
        </div>
        <div class="admin-gmb-categories__table" v-if="isDesktop || isLargeScreen">
          <ui-data-table
            :columns="columns"
            :rows="categories"
            :total-rows="categories.length"
            :is-loading="isLoading || isUpdating"
            :with-pagination="false"
            class="admin-gmb-categories__table__table"
          >
            <template slot="data-table-row" slot-scope="{ column, row, formattedRow }">
              <span>
                <div class="admin-gmb-categories__table__cell">
                  <template v-if="column.field === 'actions'" class="gmb-qa__table__block__content__name">
                    <ui-button
                      :label="$t('adminGmb.categories.buttons.seeAttributes')"
                      @click="showCategoryAttributesDetails(row.categoryId)"
                      button="primary"
                    />
                    <ui-button
                      v-if="row.moreHoursTypes"
                      :label="$t('adminGmb.categories.buttons.seeHoursTypes')"
                      @click="showCategoryHoursTypesDetails(row.categoryId, row.moreHoursTypes)"
                      button="primary"
                    />
                    <ui-button
                      v-if="row.serviceTypes"
                      :label="$t('adminGmb.categories.buttons.seeServices')"
                      @click="showCategoryServicesDetails(row.categoryId, row.serviceTypes)"
                      button="primary"
                    />
                  </template>
                  <template v-else>
                    {{ formattedRow[column.field] }}
                  </template>
                </div>
              </span>
            </template>
          </ui-data-table>
          <div class="admin-gmb-categories__table__button">
            <ui-button
              :label="$t('gmbQa.buttons.loadMore')"
              v-if="hasMoreCategoriesToLoad"
              @click="loadMoreGmbCategories"
              button="cta"
              variant="data"
            />
          </div>
        </div>
      </template>
    </screen-card>
    <modal-gmb-attributes v-if="isModalOpen" :title="modal.title" :params="modal.params" @closed="closedModal" />
    <modal-gmb-services v-if="isModalOpen" :title="modal.title" :params="modal.params" @closed="closedModal" />
    <modal-gmb-hours-types v-if="isModalOpen" :title="modal.title" :params="modal.params" @closed="closedModal" />
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiButton from '@/components/UI/Button.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import ModalGmbAttributes from '@/components/Modal/GmbAttributes.vue'
import ModalGmbServices from '@/components/Modal/GmbServices.vue'
import ModalGmbHoursTypes from '@/components/Modal/GmbHoursTypes.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'AdminGmbCategories',
  components: {
    ScreenGrid,
    ScreenCard,
    UiDropdown,
    UiDataTable,
    UiDataList,
    UiDataSearch,
    SkeletonLine,
    ModalGmbAttributes,
    ModalGmbServices,
    ModalGmbHoursTypes,
    UiButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      search: '',
      countryCode: null,
      languageCode: null,
      totalRows: 0,
      isUpdating: false,
      isModalOpen: false,
      isScrolling: false,
      modal: {
        params: null,
      },
    }
  },
  watch: {
    search: 'loadGmbCategories',
    countryCode: 'loadGmbCategories',
    languageCode: 'loadGmbCategories',
  },
  computed: {
    ...mapState({
      availablesCountries: state => state.gmb.availablesCountries,
      languageCodes: state => state.gmb.languageCodes,
      categories: state => state.gmb.categories?.data || [],
      attributes: state => state.gmb.attributes,
    }),
    ...mapGetters({
      hasMoreCategoriesToLoad: 'gmb/hasMoreCategoriesToLoad',
    }),
    countriesOptions() {
      return Object.entries(this.availablesCountries).map(obj => ({
        id: obj[0],
        label: obj[1],
      }))
    },
    languageCodeOptions() {
      return Object.entries(this.languageCodes).map(obj => ({
        id: obj[0],
        label: obj[1],
      }))
    },
    columns() {
      return [
        {
          label: this.$t('adminGmb.categories.column.categoryId'),
          field: 'categoryId',
          sortable: false,
        },
        {
          label: this.$t('adminGmb.categories.column.displayName'),
          field: 'displayName',
          sortable: false,
        },
        {
          label: this.$t('adminGmb.categories.column.actions'),
          field: 'actions',
          width: '380px',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getGeoJsons: 'gmb/getGeoJsons',
      loadCategories: 'gmb/loadCategories',
      loadMoreCategories: 'gmb/loadMoreCategories',
      getAttributes: 'gmb/getAttributes',
      clearStore: 'gmb/clearStore',
    }),
    async loadMoreGmbCategories() {
      this.isUpdating = true
      await this.loadMoreCategories()
      this.isUpdating = false
    },
    async loadGmbCategories() {
      if (this.countryCode === null || this.languageCode === null) {
        return
      }
      this.isUpdating = true
      await this.loadCategories({
        regionCode: this.countryCode.id,
        locale: this.languageCode.id,
        searchTerm: this.search,
        view: 'FULL',
      })
      this.isUpdating = false
    },
    showCategoryAttributesDetails(categoryId) {
      this.modal.title = this.$t('adminGmb.categories.modal.title', {
        category: categoryId,
        countryCode: this.countryCode.label,
        languageCode: this.languageCode.label,
      })
      this.modal.params = {
        countryCode: this.countryCode.id,
        languageCode: this.languageCode.id,
        categoryId: categoryId,
      }
      this.isModalOpen = true
      this.$nextTick(() => {
        this.$modal.show('modal-gmb-attributes')
      })
    },
    showCategoryServicesDetails(categoryId, serviceTypes) {
      this.modal.title = this.$t('adminGmb.services.modal.title', {
        category: categoryId,
        countryCode: this.countryCode.label,
        languageCode: this.languageCode.label,
      })
      this.modal.params = {
        serviceTypes,
      }
      this.isModalOpen = true
      this.$nextTick(() => {
        this.$modal.show('modal-gmb-services')
      })
    },
    showCategoryHoursTypesDetails(categoryId, moreHoursTypes) {
      this.modal.title = this.$t('adminGmb.hoursTypes.modal.title', {
        category: categoryId,
        countryCode: this.countryCode.label,
        languageCode: this.languageCode.label,
      })
      this.modal.params = {
        moreHoursTypes,
      }
      this.isModalOpen = true
      this.$nextTick(() => {
        this.$modal.show('modal-gmb-hours-types')
      })
    },
    closedModal() {
      this.isModalOpen = false
    },
  },
  beforeDestroy() {
    this.clearStore()
  },
}
</script>

<style lang="scss" scoped>
.admin-gmb-categories {
  &__top {
    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    &__dropdown {
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
        width: calc(50% - #{$gutter-tablet / 2});
      }

      @media (min-width: $screen-xl) {
        margin-bottom: $gutter-desktop;
        width: calc(50% - #{$gutter-desktop / 2});
      }
    }
  }

  &__search {
    @media (max-width: $screen-sm) {
      margin: 0 (-$gutter-mobile);
    }
  }

  &__list {
    position: relative;
    margin: 0 (-$gutter-mobile) (-$gutter-mobile);

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__row {
      width: 100%;

      &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.5em;

        &__left-button {
          margin-right: 0.5rem;
        }
      }
      &__content {
        flex: 1;

        &__name {
          font-weight: 600;
        }

        &__category {
          @include text-ellipsis(1, 12px);

          margin-top: 3px;
          font-size: $font-size-sm;
          font-style: normal;

          @media (min-width: $screen-sm) {
            @include text-ellipsis(1, 14px);

            margin-right: $gutter-tablet;
            font-size: $font-size-default;
          }
        }
      }
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }
  }

  &__table {
    &__table {
      display: none;

      @media (min-width: $screen-md) {
        display: block;
        min-height: calc(650px - 3rem);
        max-height: calc(650px - 3rem);
      }
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }

    &__cell {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
