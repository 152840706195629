<template>
  <screen-grid class="admin-gmb-accounts">
    <screen-card :title="$t('adminGmb.accounts.title')" ratio="1-1" :is-loading="isLoading">
      <template v-slot:body>
        <div class="admin-gmb-accounts__list">
          <ui-data-list
            :items="adminAccounts"
            :is-loading="isLoading"
            :is-scrolling="isScrolling"
            :no-more-data="noMoreData"
            @click="goToAccount({ row: $event })"
          >
            <template v-slot:skeleton>
              <div class="admin-gmb-accounts__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <div class="admin-gmb-accounts__list__content">
                <div class="admin-gmb-accounts__list__content__name">
                  {{ slotProps.item.accountName }}
                </div>
                <address class="admin-gmb-accounts__list__content__line">
                  {{ slotProps.item.role }} ({{ slotProps.item.type }})
                </address>
              </div>
              <span class="admin-gmb-accounts__list__status">
                {{ slotProps.item.state_status }}
              </span>
            </template>
          </ui-data-list>
        </div>
        <div class="admin-gmb-accounts__table">
          <ui-data-table
            :columns="columns"
            :rows="adminAccounts"
            :total-rows="totalRows"
            :is-loading="isLoading"
            :with-pagination="true"
            :pagination="adminAccountsPagination"
            @loadItems="loadAccounts()"
            @onCellClick="goToAccount"
          >
            <template slot="data-table-row" slot-scope="props">
              <router-link
                class="admin-gmb-accounts__table__link"
                :to="{ name: 'AccountsId', params: { name: props.row['name'] } }"
              />
              <span v-if="props.column.field === 'accountName'">
                {{ props.row['accountName'] }}
              </span>
              <span v-if="props.column.field === 'role'">
                {{ props.row['role'] }}
              </span>
              <span v-if="props.column.field === 'type'">
                {{ props.row['type'] }}
              </span>
              <span v-if="props.column.field === 'state_status'">
                {{ props.row['state_status'] }}
              </span>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'

export default {
  name: 'AdminGmbAccounts',
  components: {
    UiDataTable,
    UiDataList,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      isLoading: false,
      isScrolling: false,
      totalRows: 0,
    }
  },
  mounted() {
    this.loadAccounts()
  },
  watch: {
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrolling && !this.noMoreData) {
          this.isScrolling = true
          this.loadAccounts({ page: this.adminAccountsPagination.page + 1 })
        }
      },
    },
  },
  computed: {
    ...mapState({
      adminAccounts: state => state.gmb.adminAccounts,
      adminAccountsPagination: state => state.gmb.adminAccountsPagination,
      currentClient: state => state.user.currentClient,
    }),
    noData() {
      return this.isLoading || this.noMoreData
    },
    columns() {
      return [
        {
          label: this.$t('gmb.list.column.accountName'),
          field: 'accountName',
        },
        {
          label: this.$t('gmb.list.column.role'),
          field: 'role',
        },
        {
          label: this.$t('gmb.list.column.type'),
          field: 'type',
        },
        {
          label: this.$t('gmb.list.column.state_status'),
          field: 'state_status',
        },
      ]
    },
    noMoreData() {
      const { page, nbPages } = this.adminAccountsPagination
      return page >= nbPages
    },
  },
  methods: {
    ...mapActions({
      getFullAccounts: 'gmb/getFullAccounts',
    }),
    goToAccount(e) {
      this.$router.push({ name: 'AccountsId', params: { name: e.row.name } })
    },
    async loadAccounts({ page, size } = { page: 2, size: 100 }) {
      this.isLoading = true
      try {
        await this.getFullAccounts({ page, size })
      } finally {
        this.isLoading = false
        this.isScrolling = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-gmb-accounts {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }

    &__content {
      flex: 1;

      &__name {
        font-weight: 600;
      }

      &__line {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }

    &__status {
      margin-left: $gutter-mobile;
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      min-height: 500px;
      overflow-y: auto;
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
