//create a global variable that will point to the tooltip in the DOM
let tipObj = null

//offset along x and y in px
const OFFSET = {
  x: 20,
  y: 20,
}

/********************************************************************
 * injectTooltip(e,data)
 * inject the custom tooltip into the DOM
 ********************************************************************/
export const injectTooltip = (event, data) => {
  if (!tipObj && event) {
    //create the tooltip object
    tipObj = document.createElement('div')
    tipObj.style.background = 'var(--bg-color-hue)'
    tipObj.style.borderRadius = '6px'
    tipObj.style.padding = '8px 12px'
    tipObj.style.color = 'var(--text-color)'
    tipObj.style.fontFamily = 'Poppins, sans-serif'
    tipObj.style.textAlign = 'center'
    tipObj.innerHTML = data

    //position it
    tipObj.style.position = 'fixed'
    tipObj.style.top = event.domEvent.clientY + window.scrollY + OFFSET.y + 'px'
    tipObj.style.left = event.domEvent.clientX + window.scrollX + OFFSET.x + 'px'

    //add it to the body
    document.body.appendChild(tipObj)
  }
}

/********************************************************************
 * moveTooltip(e)
 * update the position of the tooltip based on the event data
 ********************************************************************/
export const moveTooltip = event => {
  if (tipObj && event) {
    //position it
    tipObj.style.top = event.domEvent.clientY + window.scrollY + OFFSET.y + 'px'
    tipObj.style.left = event.domEvent.clientX + window.scrollX + OFFSET.x + 'px'
  }
}

/********************************************************************
 * deleteTooltip(e)
 * delete the tooltip if it exists in the DOM
 ********************************************************************/
export const deleteTooltip = () => {
  if (tipObj) {
    //delete the tooltip if it exists in the DOM
    document.body.removeChild(tipObj)
    tipObj = null
  }
}
