<template>
  <modal name="modal-gmb-attributes" :title="title" :is-updating="false" :width="900" @closed="closed">
    <template v-slot:container>
      <div class="modal-gmb-attributes__list" v-if="isMobile || isTablet">
        <ui-data-list :items="attributes" :is-loading="isUpdating" :skeleton-loop="2">
          <template v-slot:skeleton>
            <div class="modal-gmb-attributes__list__content">
              <skeleton-line height="18px" width="80%" />
              <skeleton-line height="15px" width="50%" margin-top="3px" />
            </div>
          </template>
          <template v-slot:item="slotProps">
            <div class="modal-gmb-attributes__list__content">
              <div class="modal-gmb-attributes__list__content__name">
                {{ slotProps.item.displayName }}
              </div>
              <div class="modal-gmb-attributes__list__content__category">
                {{ slotProps.item.attributeId }}
              </div>
              <div class="modal-gmb-attributes__list__content__category">
                {{ slotProps.item.groupDisplayName }}
              </div>
            </div>
          </template>
        </ui-data-list>
      </div>
      <div class="modal-gmb-attributes__table" v-if="isDesktop || isLargeScreen">
        <ui-data-table
          :columns="columns"
          :rows="attributes"
          :total-rows="totalRows"
          :is-loading="isUpdating"
          :pagination="attributesPagination"
          @loadItems="loadAttributes($event, true)"
        >
        </ui-data-table>
      </div>
    </template>
  </modal>
</template>

<script>
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ModalGmbAttributes',
  components: {
    UiDataTable,
    UiDataList,
    SkeletonLine,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      isUpdating: false,
      totalRows: 0,
    }
  },
  mounted() {
    this.loadAttributes(null, true)
  },
  computed: {
    ...mapState({
      attributes: state => state.gmb.attributes,
      attributesPagination: state => state.gmb.attributesPagination,
    }),
    columns() {
      return [
        {
          label: this.$t('adminGmb.attributes.column.groupDisplayName'),
          field: 'groupDisplayName',
        },
        {
          label: this.$t('adminGmb.attributes.column.displayName'),
          field: 'displayName',
        },
        {
          label: this.$t('adminGmb.attributes.column.valueType'),
          field: 'valueType',
        },
        {
          label: this.$t('adminGmb.attributes.column.attributeId'),
          field: 'attributeId',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getAttributes: 'gmb/getAttributes',
      clearAttributes: 'gmb/clearAttributes',
    }),
    loadAttributes(tableParams = null, updating = false) {
      if (updating) {
        this.isUpdating = true
      }
      this.getAttributes({
        tableParams,
        countryCode: this.params.countryCode,
        languageCode: this.params.languageCode,
        categoryId: this.params.categoryId,
      })
        .then(() => {
          this.totalRows = this.locationsPagination.nbResults
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isUpdating = false
        })
    },
    closed() {
      this.clearAttributes()
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-gmb-attributes {
  &__list {
    position: relative;
    margin: (-$gutter-mobile) (-$gutter-mobile);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;

      &__name {
        font-weight: 600;
      }

      &__category {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      min-height: 350px;
      max-height: 350px;
    }
  }
}
</style>
