<template>
  <div class="admin-gmb" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      class="locations-id__header"
      title="GMB (Admin)"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      v-if="!hasError"
      :isBeta="true"
    >
    </screen-header>
    <screen-container direction="column">
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />
      <!-- Countries Section -->
      <admin-gmb-countries v-if="currentTab === 'countries'" :is-loading="isLoading" />

      <!-- Categories Section -->
      <admin-gmb-categories v-if="currentTab === 'categories'" :is-loading="isLoading" />

      <!-- Search Chain Section -->
      <admin-gmb-search-chain v-if="currentTab === 'search'" :is-loading="isLoading" />

      <!-- Invitations Section -->
      <admin-gmb-invitations v-if="currentTab === 'invitations'" :is-loading="isLoading" />

      <!-- Accounts Section -->
      <admin-gmb-accounts v-if="currentTab === 'accounts'" :is-loading="isLoading" />
    </screen-container>
  </div>
</template>

<script>
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import AdminGmbCountries from '@/components/Admin/Gmb/Countries.vue'
import AdminGmbCategories from '@/components/Admin/Gmb/Categories.vue'
import AdminGmbSearchChain from '@/components/Admin/Gmb/SearchChain.vue'
import AdminGmbInvitations from '@/components/Admin/Gmb/Invitations.vue'
import AdminGmbAccounts from '@/components/Admin/Gmb/Accounts.vue'

export default {
  name: 'AdminGmb',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    AdminGmbCountries,
    AdminGmbCategories,
    AdminGmbSearchChain,
    AdminGmbInvitations,
    AdminGmbAccounts,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      tabsElement: null,
      hasError: false,
    }
  },
  beforeMount() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('countries')
    }
  },
  computed: {
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('adminGmb.breadcrumb'),
          route: {
            name: 'AdminGmb',
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('adminGmb.tab.countries'),
          key: 'countries',
        },
        {
          label: this.$t('adminGmb.tab.categories'),
          key: 'categories',
        },
        {
          label: this.$t('adminGmb.tab.search'),
          key: 'search',
        },
        {
          label: this.$t('adminGmb.tab.invitations'),
          key: 'invitations',
        },
        {
          label: this.$t('adminGmb.tab.accounts'),
          key: 'accounts',
        },
      ]
    },
  },
  methods: {
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
  },
}
</script>
