<template>
  <screen-grid class="admin-gmb-invitations">
    <screen-card :title="$t('adminGmb.invitations.title')" ratio="1-1" :is-loading="isLoading">
      <template v-slot:body>
        <div class="admin-gmb-invitations__list" v-if="isMobile || isTablet">
          <ui-data-list
            v-if="invitations.length > 0"
            @click="selectInvitationInDataList"
            :items="invitations"
            :is-loading="isUpdating"
          >
            <template v-slot:skeleton>
              <div class="admin-gmb-invitations__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
            </template>
            <template v-slot:item="slotProps">
              <div class="admin-gmb-invitations__list__row__content">
                <div class="admin-gmb-invitations__list__row__content">
                  <div class="admin-gmb-invitations__list__row__content__name">
                    {{ slotProps.item.name }}
                  </div>
                  <div class="admin-gmb-invitations__list__row__content__category">
                    {{ slotProps.item.role }}
                  </div>
                  <div class="admin-gmb-invitations__list__row__content__address">
                    {{ slotProps.item.address }}
                  </div>
                </div>
                <div class="admin-gmb-invitations__list__row__actions">
                  <ui-button
                    variant="success"
                    :label="$t('adminGmb.invitations.buttons.accept')"
                    @click="showClientList()"
                    button="primary"
                  />
                </div>
              </div>
            </template>
          </ui-data-list>

          <div class="admin-gmb-invitations__list__empty" v-if="invitations.length <= 0">
            {{ $t('adminGmb.invitations.noData') }}
          </div>
        </div>

        <div class="admin-gmb-invitations__table" v-if="isDesktop || isLargeScreen">
          <ui-data-table
            @onCellClick="selectInvitationInDataTable"
            :columns="columns"
            :rows="invitations"
            :total-rows="invitations.length"
            :is-loading="isLoading || isUpdating"
            :with-pagination="false"
            class="admin-gmb-invitations__table__table"
          >
            <template slot="data-table-row" slot-scope="{ column, formattedRow }">
              <span>
                <div class="admin-gmb-invitations__table__cell">
                  <template v-if="column.field === 'actions'" class="gmb-qa__table__block__content__name">
                    <ui-button
                      variant="success"
                      :label="$t('adminGmb.invitations.buttons.accept')"
                      @click="showClientList()"
                      button="primary"
                    />
                  </template>
                  <template v-else>
                    {{ formattedRow[column.field] }}
                  </template>
                </div>
              </span>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
    <modal-client-list
      v-if="isModalOpen"
      :title="title"
      :account="selectedAccount"
      @onSendInvitationSuccess="sendInvitationSuccess"
      @onSendInvitationError="sendInvitationError"
      @closed="closedModal"
    />
  </screen-grid>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ModalClientList from '@/components/Modal/ClientList.vue'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiButton from '@/components/UI/Button.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { notification } from '@/utils/notification.util'

export default {
  name: 'AdminGmbInvitations',
  components: {
    ScreenGrid,
    ScreenCard,
    ModalClientList,
    UiDataTable,
    SkeletonLine,
    UiDataList,
    UiButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      isUpdating: false,
      isModalOpen: false,
      title: this.$t('adminGmb.invitation.modal.title'),
      selectedAccount: '',
    }
  },
  async mounted() {
    await this.loadGmbInvitations()
  },
  computed: {
    ...mapState({
      invitations: state => state.gmb.invitations.results || [],
    }),
    columns() {
      return [
        {
          label: this.$t('adminGmb.invitations.column.name'),
          field: 'name',
          sortable: false,
        },
        {
          label: this.$t('adminGmb.invitations.column.role'),
          field: 'role',
          sortable: false,
        },
        {
          label: this.$t('adminGmb.invitations.column.adress'),
          field: 'address',
          sortable: false,
        },
        {
          label: this.$t('adminGmb.invitations.column.actions'),
          field: 'actions',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      toggleNotifications: 'backoffice/toggleNotifications',
      loadInvitations: 'gmb/loadInvitations',
    }),
    async loadGmbInvitations() {
      this.isUpdating = true
      await this.loadInvitations()
      this.isUpdating = false
    },
    showClientList() {
      this.isModalOpen = true
      this.$nextTick(() => {
        this.$modal.show('modal-client')
      })
    },
    sendInvitationSuccess() {
      this.loadGmbInvitations()
      notification({
        text: this.$t('adminGmb.invitations.message.success'),
        type: 'success',
      })
      this.closedModal()
    },
    sendInvitationError(error) {
      this.loadGmbInvitations()
      notification({
        text: error ? error : this.$t('adminGmb.invitations.message.error'),
        type: 'error',
      })
      this.closedModal()
    },
    closedModal() {
      this.isModalOpen = false
    },
    selectInvitationInDataTable(value) {
      this.selectedAccount = value.row.account
    },
    selectInvitationInDataList(value) {
      this.selectedAccount = value.account
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-gmb-invitations {
  &__list {
    position: relative;
    margin: 0 (-$gutter-mobile) (-$gutter-mobile);

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__row {
      width: 100%;

      &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.5em;

        &__left-button {
          margin-right: 0.5rem;
        }
      }
      &__content {
        flex: 1;

        &__name {
          font-weight: 600;
        }

        &__category {
          @include text-ellipsis(1, 12px);

          margin-top: 3px;
          font-size: $font-size-sm;
          font-style: normal;

          @media (min-width: $screen-sm) {
            @include text-ellipsis(1, 14px);

            margin-right: $gutter-tablet;
            font-size: $font-size-default;
          }
        }

        &__address {
          margin: 3px 0;
          font-size: $font-size-sm;
        }
      }
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }

    &__empty {
      margin-bottom: 5px;
      text-align: center;
      color: $grey-heather;
    }
  }

  &__table {
    &__table {
      display: none;

      @media (min-width: $screen-md) {
        display: block;
        min-height: calc(650px - 3rem);
        max-height: calc(650px - 3rem);
      }
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }

    &__cell {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
