<template>
  <modal
    name="modal-client"
    :title="title"
    :has-apply="hasModelActiveItem"
    :applyLabel="this.$t('adminGmb.invitation.buttons.validate')"
    :width="500"
    height="60%"
    @closed="closed"
    @save="sendInvitations"
  >
    <template v-slot:container>
      <div class="modal-client__section" v-for="(item, id) in clientsDisplayed" :key="`client-${id}`">
        <template>
          <ui-radio :value="item.gatoreviewsId" :id="`radio ${item.gatoreviewsId}`" v-model="modelActiveItem">
            {{ item.name }}
          </ui-radio>
        </template>
      </div>
    </template>
  </modal>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import { sendInvitation } from '@/services/gmb.service'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ModalClientList',
  components: {
    UiRadio,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    account: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelActiveItem: '',
    }
  },
  async mounted() {
    await this.getClients({ resetClients: true, search: null })
  },
  computed: {
    ...mapState({
      clients: state => state.client.clients,
    }),
    clientsDisplayed() {
      return this.clients.filter(client => {
        return (
          (typeof client.gatoreviewsId === 'string' && client.gatoreviewsId.length > 0) ||
          typeof client.gatoreviewsId === 'number'
        )
      })
    },
    hasModelActiveItem() {
      return this.modelActiveItem != ''
    },
  },
  methods: {
    ...mapActions({
      getClients: 'client/getClients',
    }),
    async sendInvitations() {
      try {
        const response = await sendInvitation(this.account, this.modelActiveItem)
        if (response.status === 'success') {
          this.$emit('onSendInvitationSuccess', response)
        } else {
          this.$emit('onSendInvitationError')
        }
      } catch (err) {
        this.$emit('onSendInvitationError', err)
      }
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-client {
  &__section {
    display: flex;
    flex-direction: row;
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__switch,
    &__label {
      text-transform: capitalize;
      font-weight: 600;
    }

    &__checkbox {
      text-transform: capitalize;
    }
  }
}
</style>
