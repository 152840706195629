<template>
  <modal name="modal-gmb-services" :title="title" :is-updating="false" :width="900" @closed="closed">
    <template v-slot:container>
      <div class="modal-gmb-services__list" v-if="isMobile || isTablet">
        <ui-data-list :items="params.serviceTypes" :is-loading="false" :skeleton-loop="2">
          <template v-slot:skeleton>
            <div class="modal-gmb-services__list__content">
              <skeleton-line height="18px" width="80%" />
              <skeleton-line height="15px" width="50%" margin-top="3px" />
            </div>
          </template>
          <template v-slot:item="slotProps">
            <div class="modal-gmb-services__list__content">
              <div class="modal-gmb-services__list__content__name">
                {{ slotProps.item.displayName }}
              </div>
              <div class="modal-gmb-services__list__content__category">
                {{ slotProps.item.serviceTypeId }}
              </div>
            </div>
          </template>
        </ui-data-list>
      </div>
      <div class="modal-gmb-services__table" v-if="isDesktop || isLargeScreen">
        <ui-data-table
          :columns="columns"
          :rows="params.serviceTypes"
          :total-rows="params.serviceTypes.length"
          :with-pagination="false"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { onScroll } from '@/mixins/scroll.mixin'

export default {
  name: 'ModalGmbServices',
  components: {
    UiDataTable,
    UiDataList,
    SkeletonLine,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  mixins: [onScroll],
  computed: {
    columns() {
      return [
        {
          label: this.$t('adminGmb.services.column.displayName'),
          field: 'displayName',
        },
        {
          label: this.$t('adminGmb.services.column.serviceTypeId'),
          field: 'serviceTypeId',
        },
      ]
    },
  },
  methods: {
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-gmb-services {
  &__list {
    position: relative;
    margin: (-$gutter-mobile) (-$gutter-mobile);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;

      &__name {
        font-weight: 600;
      }

      &__category {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      min-height: 350px;
      max-height: 350px;
    }
  }
}
</style>
